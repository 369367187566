<template>
  <el-dialog
      title="结算支出"
      :visible.sync="show"
      width="30%"
      center>
    <el-row>
      <el-col style="font-size: 30px; margin-bottom: 20px">
        分配账户总支出金额：{{ totalAmount }}
      </el-col>
      <el-col style="font-size: 18px">
        公司结算方式：
        <el-select v-model="spendMethod" placeholder="公司结算方式">
          <el-option
              v-for="item in spendMethodArray"
              :key="item.value"
              :label="item.name"
              :value="item.value">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">结 算</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "spend-settlement",
  props: {
    visible: {
      required: true,
      default: false,
      type: Boolean
    },
    settlementArray: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      spendMethod: '',
      spendMethodArray: [],
    }
  },
  computed: {
    // 是否展示
    show: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    },
    // 总金额
    totalAmount: {
      get() {
        let sum = 0;
        this.settlementArray.forEach(item => {
          sum += item.spendAmount;
        })
        return sum
      }
    },
    // id数组
    spendIds: {
      get() {
        let array = []
        this.settlementArray.forEach(item => {
          array.push(item.id)
        })
        return array
      }
    }
  },
  created() {
    this.$selectUtils.queryAccountIds(1).then(response => {
      this.spendMethodArray = JSON.parse(response.data.data)
    })
  },
  methods: {
    // 结算
    submit() {
      if (this.spendMethod === '') {
        this.$message.error('请选择公司结算方式！')
        return false
      }
      this.$axios({
        method: 'POST',
        url: '/spend/settlementSpend',
        data: {
          spendIds: this.spendIds,
          spendMethod: this.spendMethod
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success('结算成功')
          this.$emit('close')
          this.show = false
        }
      })
    },
    // 取消
    close() {
      this.show = false
    },
  }
}
</script>

<style scoped>

</style>