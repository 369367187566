<!--结算列表-->
<template>
  <div>
    <el-row :gutter="20" style="margin-top:15px">
      <el-button type="primary" style="margin-bottom: 20px; margin-left: 20px" @click="showSettlement">结算支出</el-button>
      <el-table
          :data="spendArray"
          ref="multipleTable"
          border
          size="medium"
          element-loading-text="拼命加载中"
          max-height="630"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
          :default-sort = "{prop: 'createDate', order: 'descending'}"
          @select="selectRow"
          @select-all="selectAllRow"
          style="width: 99%; margin-left: 10px">
        <el-table-column
            type="selection"
            width="55"
        >
        </el-table-column>
        <el-table-column prop="createDate" label="支出发生日期" align="center" sortable fixed show-overflow-tooltip></el-table-column>
        <el-table-column prop="declareMethodTime" label="申报支付日期" align="center" sortable fixed></el-table-column>
        <el-table-column prop="declareMethodName" align="center" label="申报支付方式"></el-table-column>
        <el-table-column prop="content" align="center" label="支出内容"></el-table-column>
        <el-table-column align="center" label="支出项目" prop="spendProjectsName"></el-table-column>
        <el-table-column prop="shopName" align="center" label="店铺"></el-table-column>
        <el-table-column prop="spendPeople" align="center" label="报销人"></el-table-column>
        <el-table-column prop="spendAmount" align="center" label="支出金额" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.settlement === 0">预支</el-tag>
            <el-tag type="success" v-if="scope.row.settlement === 1">结算</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="240" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="primary" size="mini" @click="showPicture(scope.row)">支出图片</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-row>
    <spend-image :visible.sync="imageVisible" v-if="imageVisible" :spend-image="spendImage" :id="id" @close="querySpendListUnsettled"></spend-image>
    <spend-settlement :visible.sync="settlementVisible" v-if="settlementVisible" :settlement-array="selection" @close="querySpendListUnsettled"></spend-settlement>
  </div>
</template>

<script>
import spendImage from "@/pages/spend/spend/spend-image";
import SpendSettlement from "@/pages/spend/spend/spend-settlement";
export default {
  name: "settlement-list",
  created() {
    this.pageInit()
  },
  components: {
    SpendSettlement,
    spendImage
  },
  data() {
    return {
      limit: 100,
      loading: true,
      total: 1,
      size: 100,
      page: 1,
      spendArray: [],
      imageVisible: false,
      settlementVisible: false,
      spendImage: '',
      id: '',
      selection: [],
      tenantCrop: localStorage.getItem('tenantCrop')
    }
  },
  methods: {
    // 查询未结算且所属申报已支付的支出列表(未结算列表)
    querySpendListUnsettled() {
      this.$axios({
        method: "GET",
        url: "/spend/queryUnsettledSpendList",
        params: {
          page: this.page,
          size: this.size,
          tenantCrop: this.tenantCrop
        }
      }).then(response => {
        if (response.data.code === 200) {
          console.log(response.data.data)
          this.spendArray = response.data.data.list
          this.total = response.data.data.total
        } else {
          this.$message.error(response.data.msg)
        }
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryAppList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryAppList()
    },
    pageInit() {
      this.querySpendListUnsettled()
    },
    showPicture(row) {
      this.spendImage = row.spendImage
      this.id = row.id
      this.imageVisible = true
    },
    selectRow(selection) {
      this.selection = selection
    },
    // 显示结算支出
    showSettlement() {
      if (this.selection.length === 0) {
        this.$message.info("请先选择要结算的支出！")
        return false
      }
      this.settlementVisible = true
    },
    selectAllRow(selection) {
      this.selection = selection
    }
  }
}
</script>

<style scoped>

</style>